import { useEffect, useState } from 'react';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';

function useHeaderAnalyticsListeners() {
  const { elementClicked, purchaseCompleted } = useAnalyticsContext();
  const [stripeId, setStripeId] = useState<string | undefined>();

  useEffect(() => {
    window.addEventListener('HEADER_ANALYTICS_EVENT', (event: CustomEvent) => {
      const { analyticsData, analyticsEventType, stripeCustomerId } =
        event.detail;
      if (analyticsEventType === 'PURCHASE_COMPLETED') {
        purchaseCompleted(analyticsData);
      }

      if (analyticsEventType === 'ELEMENT_CLICKED') {
        elementClicked(analyticsData);
      }

      if (stripeCustomerId) {
        setStripeId(stripeCustomerId);
      }
    });
  }, [elementClicked, purchaseCompleted]);

  return {
    stripeId
  };
}

export default useHeaderAnalyticsListeners;
