import clsx from 'clsx';
import type { HTMLProps, PropsWithChildren } from 'react';

type Props = HTMLProps<HTMLDivElement> & {
  className?: string;
};

const Box = ({ children, className, ...rest }: PropsWithChildren<Props>) => (
  <div {...rest} className={clsx(className, 'box-border')}>
    {children}
  </div>
);

export default Box;
