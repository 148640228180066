import {
  ACCOUNTS_OUTSIDE_ONLINE_URLS,
  ANALYTICS_DOMAIN,
  APPLICATION_URL
} from 'helpers/constants';

const analyticsDomainParam = new URLSearchParams({
  analytics_domain: ANALYTICS_DOMAIN
});

const getAccountsPurchaseMembershipURL = () =>
  `${
    ACCOUNTS_OUTSIDE_ONLINE_URLS.OUTSIDE_PLUS
  }?${analyticsDomainParam}&redirectUrl=${encodeURIComponent(window.location.href)}`;

// if component is SSR only, we have to redirect to existing url to avoid mismatch
const getSSRAccountsPurchaseMembershipURL = () =>
  `${
    ACCOUNTS_OUTSIDE_ONLINE_URLS.OUTSIDE_PLUS
  }?${analyticsDomainParam}&redirectUrl=${encodeURIComponent(APPLICATION_URL)}`;

export const getOPlusPageURL = (isSSROnly?: boolean) =>
  isSSROnly
    ? getSSRAccountsPurchaseMembershipURL()
    : getAccountsPurchaseMembershipURL();

const getDefaultAppUrl = () => `${APPLICATION_URL}`;
const getWindowLocationUrl = () => encodeURIComponent(window.location.href);

export const getSignUpURL = (isSSROnly?: boolean) => {
  const signUpSearchParams = new URLSearchParams({
    analytics_domain: ANALYTICS_DOMAIN,
    next: isSSROnly ? getDefaultAppUrl() : getWindowLocationUrl()
  });

  return `${ACCOUNTS_OUTSIDE_ONLINE_URLS.SIGN_UP}?${signUpSearchParams}`;
};

export const getProfileURL = () => ACCOUNTS_OUTSIDE_ONLINE_URLS.PROFILE;

export const getSettingsURL = () => ACCOUNTS_OUTSIDE_ONLINE_URLS.SETTINGS;

export const getSubscriptionURL = () =>
  ACCOUNTS_OUTSIDE_ONLINE_URLS.SUBSCRIPTION;
