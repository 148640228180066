import { useAuthService } from '@pocketoutdoormedia/auth-state-service';
import { useEffect } from 'react';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';
import { getCompleteAuthData } from 'apis/user-auth/queryFunction';

export const IdentifyAnalyticsService = () => {
  const authService = useAuthService();
  const { identify } = useAnalyticsContext();

  useEffect(() => {
    if (authService.userState.isAuthenticated) {
      const { rivtToken } = authService.userState;
      getCompleteAuthData(rivtToken)
        .then((data) => {
          identify({
            eventId: data.uuid,
            props: {
              canceled_at: data.membership.canceled_at,
              created_at: data.membership.created_at,
              dob: data.birthday,
              email: data.email,
              firstName: data.first_name,
              gaia_brandpass_expiration:
                data.membership.gaia_brandpass_expiration,
              gender: data.gender,
              has_gaia_brandpass: data.membership.has_gaia_brandpass,
              has_trailforks_brandpass:
                data.membership.has_trailforks_brandpass,
              lastName: data.last_name,
              membershipSubscription: data.membership.subscription?.shortname,
              membershipType: data.membership.membership_type,
              paymentFrequency:
                data.membership.subscription?.plan.shortname.includes('yearly')
                  ? 'Yearly'
                  : 'Monthly',
              signup_source: data.signup_source,
              trailforks_brandpass_expiration:
                data.membership.trailforks_brandpass_expiration,
              true_expires_at: data.membership.true_expires_at,
              true_membership_type: data.membership.true_membership_type,
              ...(data.preferences && {
                preferences: data.preferences
              }),
              ...(data.sub_preferences && {
                sub_preferences: data.sub_preferences
              })
            }
          });
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e, 'Error fetching user data');
        });
    }
  }, [authService.userState.isAuthenticated]);

  return null;
};
