import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { NavigationLink } from 'components/02-molecules/NavigationLink';
import { useToggle } from 'helpers/hooks/useToggle';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';
import { useClickAway } from 'react-use';
import { useHandleKeyDown } from 'helpers/hooks/useHandleKeyDown';

import type { NavLinkProps } from '../types';

import HeaderNavLink from './HeaderNavLink';

type Props = {
  currentPath: string;
  exactPath: string;
  isNavHidden: boolean;
  link: NavLinkProps;
};

const NavigationListItem = ({
  currentPath,
  exactPath,
  isNavHidden,
  link
}: Props) => {
  const dropdownRef = useRef(null);
  const subNavigationRef = useRef(null);
  const [isDropdownOpen, { setFalse: closeDropdown, toggle: toggleDropdown }] =
    useToggle(false);
  const { elementClicked } = useAnalyticsContext();
  const [isOverlappingRightEdge, setIsOverlappingRightEdge] = useState(false);

  const handleNavLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (link.subElements) toggleDropdown();
    elementClicked({
      name: 'main-navigation',
      type: 'link',
      url: `${window.location.origin}${event.currentTarget.pathname}`
    });
  };

  useClickAway(dropdownRef, closeDropdown);

  const handleOnEscapeDown = useHandleKeyDown('Escape', closeDropdown);

  useEffect(() => {
    if (isNavHidden) closeDropdown();
  }, [closeDropdown, isNavHidden]);

  useEffect(() => {
    if (subNavigationRef.current) {
      const { right } = subNavigationRef.current.getBoundingClientRect();
      setIsOverlappingRightEdge(window.innerWidth - right < 0);
    }
  }, [subNavigationRef]);

  return (
    <li ref={dropdownRef} className="inline-block relative">
      <HeaderNavLink
        handleNavLinkClick={handleNavLinkClick}
        hasSubmenu={!!link?.subElements}
        isActive={link.matchers.some((matcher) => matcher === currentPath)}
        link={link}
      >
        <span className="relative h-full flex items-center whitespace-nowrap">
          {link.text}
        </span>
      </HeaderNavLink>
      {link.subElements ? (
        <div
          ref={subNavigationRef}
          className={clsx(
            'absolute transition-all duration-300 w-[300px] narrow:w-[400px]',
            {
              'invisible opacity-0': !isDropdownOpen,
              'left-1/2 -translate-x-1/2': !isOverlappingRightEdge,
              'right-0': isOverlappingRightEdge,
              'visible opacity-100': isDropdownOpen
            }
          )}
          onKeyDown={handleOnEscapeDown}
          role="none"
        >
          <div className="py-2 grid grid-cols-1 rounded-lg bg-calendar-gray shadow-theme-shadow-dropdown">
            {link.subElements.map((subElement) => {
              const isActive = subElement.matchers.some(
                (matcher) => matcher === exactPath
              );
              return (
                <NavigationLink
                  key={subElement.text}
                  classes={clsx(
                    'text-12 narrow:text-18 px-6 py-4 text-left hover:text-btn-secondary-hover active:text-btn-secondary-active px-4 hover:bg-new-neutral-900 font-plus-jakarta-sans focus-visible:bg-new-neutral-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus',
                    {
                      'text-btn-secondary-hover': isActive,
                      'text-network-white': !isActive
                    }
                  )}
                  href={subElement.href}
                  label={subElement.text}
                  onClick={(event) => handleNavLinkClick(event)}
                >
                  {subElement.text}
                </NavigationLink>
              );
            })}
          </div>
        </div>
      ) : null}
    </li>
  );
};

export default NavigationListItem;
