import { createContext, useContext } from 'react';
import type { ReactElement, Dispatch } from 'react';

export const PortalRendererUpdaterContext =
  createContext<Dispatch<ReactElement>>(null);

export const PortalRendererStateContext = createContext<boolean | null>(null);

export const usePortalRendererUpdaterContext = () => {
  const ctx = useContext(PortalRendererUpdaterContext);

  if (!ctx) {
    throw new Error('Component beyond PortalRendererUpdaterContext');
  }

  return ctx;
};
