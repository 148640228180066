import { useWatchRegWall } from 'helpers/hooks/useWatchRegWall';
import type { ReactNode } from 'react';

// watch-regwall
const WatchRegWallProvider = ({ children }: { children: ReactNode }) => {
  useWatchRegWall();

  return children;
};

export default WatchRegWallProvider;
