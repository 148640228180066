import { gql } from 'graphql-request';

export const GET_CATEGORY = gql`
  query getCategory($slug: String!) {
    watchCategory(slug: $slug) {
      slug
      title
      misoReference
      playlistId
      type
      seo {
        title
        ogTitle
        description
        ogDescription
        publishedTime
        modifiedTime
        ogImage
        ogImageWidth
        ogImageHeight
      }
      subcategories {
        slug
        title
        misoReference
        playlist {
          feedId
        }
      }
    }
  }
`;

export const GET_CATEGORY_LIST = gql`
  query getCategoryList {
    watchCategoryList {
      slug
      title
      type
    }
  }
`;
