import type { WatchCategorySummary } from 'helpers/types/jwplayer-subgraph';
import { routes } from 'lib/routes';

export const parseCategoryList = (
  categoryList: Nullable<WatchCategorySummary[]>
) =>
  categoryList?.map((el: WatchCategorySummary) => ({
    href: routes.categoriesDetail(el.slug),
    matchers: [`/categories/${el.slug}`],
    slug: el.slug,
    target: '_self' as string,
    text: el.title
  }));
