import { gql } from 'graphql-request';

export const GET_MARKETING = gql`
  query getMarketing {
    cta: watchCTA {
      headline
      description
      buttonText
      priceDisclaimer
      priceDiscount
      posters {
        image
        imageAltTag
      }
    }
  }
`;
