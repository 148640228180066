import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import { routes } from 'lib/routes';

type State = {
  hasError: boolean;
};

type ErrorDetails = {
  location: string;
  mediaId?: string;
  title?: string;
};

type BaseProps = {
  children: ReactNode;
  errorDetails: ErrorDetails;
};

type FallbackProps = {
  fallbackComponent: ReactNode;
  type?: 'fallback';
};

type ActionProps = {
  callAction: () => Promise<boolean> | VoidFunction;
  type?: 'action';
};

// if type is not specified, it will do the default redirect to 500 page
type DefaultProps = {
  callAction?: never;
  fallbackComponent?: never;
  type?: undefined;
};

type Props = BaseProps & (ActionProps | DefaultProps | FallbackProps);

const defaultRedirect = () => Router.push(routes.page500());

const isDev = process.env.NODE_ENV === 'development';

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, { componentStack }: ErrorInfo) {
    const { errorDetails } = this.props;

    Sentry.captureException(error, {
      extra: {
        componentStack
      },
      tags: errorDetails
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children, type } = this.props;

    if (isDev) return children;

    if (!hasError) return children;

    if (type === 'fallback') {
      const { fallbackComponent } = this.props;
      return fallbackComponent;
    }

    if (type === 'action') {
      const { callAction } = this.props;
      callAction();
      return null;
    }

    defaultRedirect();
    return null;
  }
}

export default ErrorBoundary;
