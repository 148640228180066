import type { ReactNode } from 'react';

import {
  AnalyticsDefaultOptions,
  AnalyticsDefaultPayload,
  AnalyticsDefaultTrigger,
  AnalyticsProvider,
  AnalyticsTrigger
} from '../AnalyticsProvider';

type Props = {
  children: ReactNode;
  domain: string;
};

const HeadlessProvider = ({ children, domain }: Props) => (
  <AnalyticsProvider
    options={{
      ...AnalyticsDefaultOptions,
      ...{
        analyticsJsUrl: process.env.NEXT_PUBLIC_METAROUTER_URL,
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        writeKey: process.env.NEXT_PUBLIC_METAROUTER_ID
      }
    }}
    payload={{
      ...AnalyticsDefaultPayload,
      ...{ props: { domain } }
    }}
    trigger={AnalyticsDefaultTrigger}
  >
    <AnalyticsTrigger event="page">{children}</AnalyticsTrigger>
  </AnalyticsProvider>
);

export default HeadlessProvider;
