/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import type { ReactNode } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { prefetchMarketing } from 'apis/marketing/queries';

type Props = {
  children: ReactNode;
};

// This Provider concept was made to prefetch data and populate cache
// Data that need to be used across the whole app should be prefetched here e.g: content for the paywall modal
// Do not prefetch here any other queries related to specific pages
const CacheProvider = ({ children }: Props) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    prefetchMarketing(queryClient);
  }, [queryClient]);

  return <>{children}</>;
};

export default CacheProvider;
