import { useRouter } from 'next/router';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { useEffect } from 'react';

import { useRegisterWatchRegwallAnalytics } from './useRegisterWatchRegwallAnalytics';

// watch-regwall
export const useWatchRegWall = (mediaItem?: MediaItem) => {
  const { query } = useRouter();
  useRegisterWatchRegwallAnalytics();

  useEffect(() => {
    // hide modal for non-free mediaItems in fullscreen mode (because those already have Piano modals)
    if (
      query?.mode === 'fullscreen' &&
      mediaItem &&
      mediaItem.meterFlow !== 'FREE'
    ) {
      document.body.classList.add('tp-modal-hidden');
      return;
    }

    document.body.classList.remove('tp-modal-hidden');
  }, [mediaItem, query?.mode]);
};
