import Script from 'next/script';

const Piano = () => {
  if (process.env.NEXT_PUBLIC_PIANO_DISABLED === 'true') return null;
  if (
    !process.env.NEXT_PUBLIC_PIANO_DOMAIN ||
    !process.env.NEXT_PUBLIC_PIANO_AID
  ) {
    // TODO: move this into error-tracking service
    // eslint-disable-next-line no-console
    console.error(`Piano script has failed - missing required env variable`);
    return null;
  }

  return (
    <Script id="piano" strategy="beforeInteractive">
      {`
        const getCookie = name => document.cookie.match(new RegExp(\`(^| )\${name}=([^;]+)\`))?.at(2);

        var tp = window.tp || [];
    
        tp.push(["setExternalJWT", getCookie("_pid")])

        tp.push(["init", function() {
            tp.experience.init();
            // watch-regwall
            tp.setTags('watch-regwall');
        }]);
        
        (function(src) {
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.async = true;
            a.src = src;
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b)
        })("${process.env.NEXT_PUBLIC_PIANO_DOMAIN}/xbuilder/experience/load?aid=${process.env.NEXT_PUBLIC_PIANO_AID}");
        `}
    </Script>
  );
};

export default Piano;
