import { routes } from 'lib/routes';

import type { NavLinkProps } from './types';

export const createNavLinks = ({
  bucketList,
  categoriesList
}: {
  bucketList?: NavLinkProps[];
  categoriesList?: NavLinkProps[];
} = {}): NavLinkProps[] => [
  {
    href: routes.films(),
    matchers: ['/films', '/[title]/[id]'],
    target: '_self',
    text: 'Films'
  },
  {
    href: routes.series(),
    matchers: ['/series', '/series/[title]/[id]'],
    target: '_self',
    text: 'Series'
  },
  {
    classes: 'peer',
    href: '#',
    matchers: ['/categories', '/categories/[categoryTitle]'],
    subElements: categoriesList,
    target: '_self',
    text: 'Categories'
  },
  {
    classes: 'peer',
    href: '#',
    matchers: ['/live-events', '/live-events/[slug]'],
    subElements: [
      {
        href: routes.liveEvents(),
        matchers: ['/live-events'],
        target: '_self',
        text: 'Home'
      },
      ...(!bucketList ? [] : bucketList)
    ],
    target: '_self',
    text: 'Live & Events'
  }
];

const getRootPageTitle = (links: NavLinkProps[], pathname: string) =>
  links.find((link) => link.href === pathname)?.text;

const getCategoriesPageTitle = (
  links: NavLinkProps[],
  categoryTitle?: string
) => {
  const subcategories = links.find(
    (link) => link.text === 'Categories'
  )?.subElements;

  return subcategories?.length
    ? subcategories.find((link) => link.slug === categoryTitle)?.text
    : null;
};

export const getPageTitle = (
  links: NavLinkProps[],
  pathname: string,
  categoryTitle?: string
) =>
  pathname.includes(routes.categories())
    ? getCategoriesPageTitle(links, categoryTitle)
    : getRootPageTitle(links, pathname);
