import isDom from 'helpers/utils/isDom';

type Output = {
  isBrowser: boolean;
  isServer: boolean;
};

export const useSSR = (): Output => ({
  isBrowser: isDom(),
  isServer: !isDom()
});
