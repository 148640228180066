import { datadogLogs } from '@datadog/browser-logs';

export type InitDatadogProps = {
  /** The RUM application ID. */
  appId: string;
  /** A Datadog client token. */
  clientToken: string;
  /** The application’s environment DEFAULT: production */
  env?: string;
  /**  */
  forwardErrorsToLogs?: boolean;
  /** The percentage of sessions to track: 100 for all, 0 for none. DEFAULT: 100 */
  sampleRate?: number;
  /** The service name for your application. */
  service: string;
  /**  Whether the application is or US or EU */
  site?: string;
  /** Enables automatic collection of users actions. DEFAULT: true */
  trackInteractions?: boolean;
};

export function InitDatadog({
  clientToken,
  forwardErrorsToLogs = true,
  site = 'datadoghq.com'
}: InitDatadogProps) {
  datadogLogs.init({
    clientToken,
    forwardErrorsToLogs,
    site
  });
}
