import type { ReactNode } from 'react';
import { useEffect, useState, useRef } from 'react';
import { usePosterPreview } from 'helpers/hooks/usePosterPreview';
import { useHover } from 'helpers/hooks/useHover';
import clsx from 'clsx';

import Portal from '../Portal';
import Transition from '../Transition';

import { getConfig } from './motion';

type Props = {
  animationKey: string;
  children: ReactNode;
  isCarouselItem?: boolean;
  isHoverable?: boolean;
  renderContent: (closeHover: VoidFunction, previewHeight: number) => ReactNode;
};

export const PREVIEW_DOM_ID = 'preview-portal';

const Preview = ({
  animationKey,
  children,
  isHoverable = true,
  renderContent
}: Props) => {
  const ref = useRef(null);

  const [isHover, handlers, closeHover] = useHover();

  const [shouldRecalculate, setShouldRecalculate] = useState(false);

  useEffect(() => {
    setShouldRecalculate(!isHover || !isHoverable);
  }, [isHover, isHoverable]);

  const { position, previewHeight, previewWidth } = usePosterPreview({
    ref,
    shouldRecalculate
  });

  const [isAnimating, setIsAnimating] = useState(false);
  const [isFadeInComplete, setIsFadeInComplete] = useState(false);
  const handleAnimationStart = () => {
    setIsAnimating(true);
  };
  const handleAnimationComplete = (animation) => {
    if (animation === 'fadeIn') {
      setIsFadeInComplete(true);
    } else if (animation === 'fadeOut') {
      setIsFadeInComplete(false);
    }
    setIsAnimating(false);
  };

  return (
    <div
      ref={ref}
      {...handlers}
      className={clsx({
        'pointer-events-none': isFadeInComplete && isAnimating
      })}
    >
      {children}
      {position && (
        <Portal id={PREVIEW_DOM_ID}>
          <Transition
            className={clsx('absolute', {
              'pointer-events-none':
                (isFadeInComplete && isAnimating) || !isHover
            })}
            isVisible={isHover && isHoverable}
            style={{
              left: position.left,
              top: position.top,
              width: previewWidth
            }}
            {...getConfig(animationKey)}
            onAnimationComplete={handleAnimationComplete}
            onAnimationStart={handleAnimationStart}
          >
            {renderContent(closeHover, previewHeight)}
          </Transition>
        </Portal>
      )}
    </div>
  );
};

export default Preview;
