import { Y_OFFSET } from 'helpers/constants/posterPreview';

const variants = {
  fadeIn: {
    opacity: 1,
    transition: { delay: 0.2, duration: 0.3, ease: 'easeInOut' },
    y: 0
  },
  fadeOut: {
    opacity: 0,
    transition: { duration: 0.3, ease: 'easeInOut' },
    y: Y_OFFSET
  }
};

export const getConfig = (key: string) => ({
  animate: 'fadeIn',
  exit: 'fadeOut',
  initial: 'fadeOut',
  key,
  variants
});
