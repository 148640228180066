import { useQuery } from '@tanstack/react-query';

import { getCategoryList } from './queryFunctions';

export const CATEGORY_LIST_QUERY_KEY = 'categoryList';

export const useCategoryList = () =>
  useQuery({
    placeholderData: {
      watchCategoryList: []
    },
    queryFn: getCategoryList,
    queryKey: [CATEGORY_LIST_QUERY_KEY]
  });
