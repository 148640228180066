import { gql } from 'graphql-request';
import { MEDIA_ITEM_FRAGMENT } from 'apis/fragments';

export const GET_EVENTS_BUCKET = gql`
  query EventsBucket($slug: String!) {
    eventsBucket(slug: $slug) {
      heroImage
      title
      description
      slug
      sponsors {
        image
        name
        url
      }
      playlist {
        mediaItems {
          ...MediaItemFragment
        }
      }
      numberOfEvents
      live {
        title
        hls
        date
        location
      }
      previewImage
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
`;

export const GET_EVENTS_BUCKET_LIVE_HLS = gql`
  query EventsBucket($slug: String!) {
    eventsBucket(slug: $slug) {
      description
      live {
        hls
        title
        meterFlow
      }
    }
  }
`;

export const GET_BUCKET_LIST = gql`
  query BucketList {
    eventsBucketList {
      slug
      title
    }
  }
`;
