/* eslint-disable class-methods-use-this */
import { PianoEventName } from 'helpers/constants/piano';
import isDom from 'helpers/utils/isDom';

import type { AddHandlerMethod } from './types';
import {
  sendRemoteActionToPianoTemplate,
  sendTimeCountingUpdate,
  sendToPianoTemplate
} from './templateCommunication';

const isPianoDisabled = process.env.NEXT_PUBLIC_PIANO_DISABLED === 'true';

// TODO: this could be a decorator
function throwOnServer() {
  if (!isDom()) throw new Error('Piano should not be used server side');
}

export class PianoProxy extends EventTarget {
  constructor() {
    super();
    if (isDom() && !isPianoDisabled) this.registerHandlers();
  }

  get customVariables() {
    throwOnServer();
    return window.tp.customVariables;
  }

  setCustomVariable(
    name: string,
    value: Nullable<PianoPrimitive> | Nullable<PianoPrimitive>[]
  ) {
    throwOnServer();
    return window.tp.push(['setCustomVariable', name, value]);
  }

  removeCustomVariable(name: string) {
    throwOnServer();
    return window.tp.push(['setCustomVariable', name, null]);
  }

  setCustomVariables(variables: Record<string, Nullable<PianoPrimitive>>) {
    Object.entries(variables).forEach(([name, value]) =>
      this.setCustomVariable(name, value)
    );
  }

  removeCustomVariables() {
    throwOnServer();
    Object.keys(this.customVariables || {}).forEach(this.removeCustomVariable);
  }

  get tags() {
    throwOnServer();
    return window.tp.tags;
  }

  setTags(tags: string[]) {
    throwOnServer();
    return window.tp.push(['setTags', tags]);
  }

  executeExperience() {
    throwOnServer();
    return window.tp.experience.execute();
  }

  sendToTemplate(...args: Parameters<typeof sendToPianoTemplate>) {
    throwOnServer();
    return sendToPianoTemplate(...args);
  }

  sendRemoteActionToTemplate(
    ...args: Parameters<typeof sendRemoteActionToPianoTemplate>
  ) {
    throwOnServer();
    return sendRemoteActionToPianoTemplate(...args);
  }

  sendTimeCountingUpdate(...args: Parameters<typeof sendTimeCountingUpdate>) {
    throwOnServer();
    return sendTimeCountingUpdate(...args);
  }

  private addHandler: AddHandlerMethod = function (eventName, handler) {
    throwOnServer();
    return window.tp.push(['addHandler', eventName, handler]);
  };

  private registerHandlers() {
    this.addHandler('showTemplate', (payload) =>
      this.dispatchEvent(
        new CustomEvent(PianoEventName.ShowTemplate, { detail: payload })
      )
    );

    this.addHandler('setResponseVariable', (payload) =>
      this.dispatchEvent(
        new CustomEvent(PianoEventName.SetResponseVariable, { detail: payload })
      )
    );
    this.addHandler('customEvent', (payload) => {
      this.dispatchEvent(
        new CustomEvent(payload.eventName, { detail: payload })
      );
    });
  }
}

export const piano = new PianoProxy();
