import clsx from 'clsx';

export type Variant =
  | 'display'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'heading7'
  | 'heading8'
  | 'heading9'
  | 'heading10';

export type Level = 1 | 2 | 3 | 4 | 5 | 6;

export type ResponsiveVariant = 'large' | 'medium' | 'small' | 'xSmall';

type ResponsiveClassMap = Record<ResponsiveVariant, string>;

type CommonProps = {
  classes?: string;
  level: Level;
  text: JSX.Element | string;
};

type VariantProps = CommonProps & {
  responsiveVariant?: never;
  variant: Variant;
};

type ResponsiveVariantProps = CommonProps & {
  responsiveVariant: ResponsiveVariant;
  variant?: never;
};

type Props = ResponsiveVariantProps | VariantProps;

type ClassMap = Record<Variant, string>;

const classMap: ClassMap = {
  display: 'text-display leading-[110%]',
  heading1: 'text-headline-1 leading-[120%]',
  heading10: 'text-headline-10 leading-[145%]',
  heading2: 'text-headline-2 leading-[120%]',
  heading3: 'text-headline-3 leading-[130%]',
  heading4: 'text-headline-4 leading-[125%]',
  heading5: 'text-headline-5 leading-[135%]',
  heading6: 'text-headline-6 leading-[140%]',
  heading7: 'text-headline-7 leading-[145%]',
  heading8: 'text-headline-8 leading-[145%]',
  heading9: 'text-headline-9 leading-[145%]'
};

const responsiveClassMap: ResponsiveClassMap = {
  large: `${classMap.heading2} narrow-x:text-headline-1 narrow-x:leading-[120%]`,
  medium: `${classMap.heading6} narrow-x:text-headline-4 narrow-x:leading-[125%]`,
  small: `${classMap.heading7} narrow-x:text-headline-6 narrow-x:leading-[140%]`,
  xSmall: `${classMap.heading10} narrow-x:text-headline-9 narrow-x:leading-[145%]`
};

const commonClasses = 'font-700 font-plus-jakarta-sans';

const Heading = ({
  classes,
  level,
  responsiveVariant,
  text,
  variant
}: Props) => {
  const Tag: keyof JSX.IntrinsicElements = `h${level}`;
  const className = responsiveClassMap[responsiveVariant] || classMap[variant];

  return <Tag className={clsx(commonClasses, className, classes)}>{text}</Tag>;
};

export default Heading;
