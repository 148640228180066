import type { AnimationLifecycles, AnimationProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import type { HTMLProps, ReactNode } from 'react';

type Props = AnimationLifecycles &
  AnimationProps &
  Pick<HTMLProps<HTMLDivElement>, 'className' | 'style'> & {
    children?: ReactNode;
    isVisible: boolean;
  };

const Transition = ({ children, isVisible, ...rest }: Props) => (
  <AnimatePresence>
    {isVisible && <motion.div {...rest}>{children}</motion.div>}
  </AnimatePresence>
);

export default Transition;
