import type { NavigationCallbacksRecord } from '@pocketoutdoormedia/global-header/dist/types';
import Router from 'next/router';
import { getSignInURL, getSignOutURL } from '@pocketoutdoormedia/global-header';
import { OUTSIDE_INC_URLS } from 'helpers/constants';
import {
  getOPlusPageURL,
  getProfileURL,
  getSettingsURL,
  getSignUpURL,
  getSubscriptionURL
} from 'helpers/utils/accountsUrls';

import { routes } from './routes';

export const globalHeaderNavigationConfig: NavigationCallbacksRecord = {
  BOOKMARKS: () => Router.push(routes.bookmarks()),
  HELP: () => Router.push(OUTSIDE_INC_URLS.HELP),
  MY_BENEFITS: () => Router.push(routes.myBenefits()),
  OPLUS_SITE: () => Router.push(getOPlusPageURL()),
  PROFILE: () => Router.push(getProfileURL()),
  REGISTER: () => Router.push(getSignUpURL()),
  SETTINGS: () => Router.push(getSettingsURL()),
  SIGN_IN: () => Router.push(getSignInURL()),
  SIGN_OUT: () => Router.push(getSignOutURL()),
  SUBSCRIPTION: () => Router.push(getSubscriptionURL())
};
