import TrackingLink from 'components/02-molecules/TrackingLink';
import { SCOUT_APP_URL } from 'helpers/constants';

const ScoutLink = () => (
  <TrackingLink
    analytics={{
      name: 'Scout icon',
      placement: 'header'
    }}
    href={SCOUT_APP_URL}
    isExternal
    title="Scout App"
  >
    <span className="!flex hover:bg-white/20 rounded-full justify-center items-center w-10 h-10 focus-visible:bg-white/20 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus">
      <svg
        className="w-full h-full"
        fill="none"
        height="44"
        viewBox="0 0 44 44"
        width="44"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            clipRule="evenodd"
            d="M31 15.5C31 14.3954 31.8954 13.5 33 13.5C31.8954 13.5 31 12.6046 31 11.5C31 12.6046 30.1046 13.5 29 13.5C30.1046 13.5 31 14.3954 31 15.5Z"
            fill="#FFD100"
            fillRule="evenodd"
            id="scout-star"
            stroke="#FFD100"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.999583"
          />
          <path
            d="M24.9474 13.5H16C14.343 13.5 13 14.843 13 16.5V24.5C13 26.157 14.343 27.5 16 27.5H17.5V30.5L22 27.5H28C29.657 27.5 31 26.157 31 24.5V19.5"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </svg>
    </span>
  </TrackingLink>
);

export default ScoutLink;
