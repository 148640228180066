import { client } from 'lib/graphqlRequest';
import type { QueryEventsBucketArgs } from 'helpers/types/jwplayer-subgraph';

import type {
  GetEventsBucketResponse,
  GetEventsBucketParams,
  GetBucketListResponse
} from './types';
import {
  GET_EVENTS_BUCKET,
  GET_BUCKET_LIST,
  GET_EVENTS_BUCKET_LIVE_HLS
} from './graphql';

export const getEventsBucket = (variables: GetEventsBucketParams) =>
  client.request<GetEventsBucketResponse, QueryEventsBucketArgs>(
    GET_EVENTS_BUCKET,
    variables
  );

export const getEventsBucketLiveHLS = (variables: GetEventsBucketParams) =>
  client.request<GetEventsBucketResponse, QueryEventsBucketArgs>(
    GET_EVENTS_BUCKET_LIVE_HLS,
    variables
  );

export const getBucketList = () =>
  client.request<GetBucketListResponse>(GET_BUCKET_LIST);
