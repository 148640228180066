import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

export type PianoStoreValue = {
  timeLimit: number;
};

export const PIANO_STORE_DEFAULT_VALUES: PianoStoreValue = {
  timeLimit: null
};

export const PianoStoreContext = createContext<PianoStoreValue>(
  PIANO_STORE_DEFAULT_VALUES
);

export const PianoStoreUpdaterContext =
  createContext<Dispatch<SetStateAction<PianoStoreValue>>>(null);

export const usePianoStoreContext = () => {
  const ctx = useContext(PianoStoreContext);

  if (!ctx) {
    throw new Error('Component beyond PianoStoreContext');
  }

  return ctx;
};

export const usePianoStoreUpdaterContext = () => {
  const ctx = useContext(PianoStoreUpdaterContext);

  if (!ctx) {
    throw new Error('Component beyond PianoStoreUpdaterContext');
  }

  return ctx;
};
