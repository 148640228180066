import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useToggle } from './useToggle';

type HoverEvents = 'onMouseEnter' | 'onMouseLeave' | 'onMouseOver';

type Handlers = Record<HoverEvents, VoidFunction>;

type Output = [boolean, Handlers, VoidFunction];

export const useHover = (): Output => {
  const router = useRouter();
  const [isHover, { setFalse, setTrue }] = useToggle();

  useEffect(() => {
    router.events.on('routeChangeComplete', setFalse);
    window.addEventListener('blur', setFalse);

    return () => {
      router.events.off('routeChangeComplete', setFalse);
      window.removeEventListener('blur', setFalse);
    };
  }, [setFalse, router.events]);

  const handlers = {
    onMouseEnter: setTrue,
    onMouseLeave: setFalse,
    onMouseOver: setTrue
  };

  return [isHover, handlers, setFalse];
};
