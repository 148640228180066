import type { AppContext, AppInitialProps } from 'next/app';
import { PianoStoreProvider } from 'providers/PianoStoreProvider';
import type { ComponentType } from 'react';

export const withPianoStoreProvider =
  <P extends object>(Component: ComponentType<P>) =>
  (props: AppContext & AppInitialProps) =>
    (
      <PianoStoreProvider>
        <Component {...(props as P)} />
      </PianoStoreProvider>
    );
