const RegisterEncouragementDialog =
  process.env.NEXT_PUBLIC_PIANO_TEMPLATE_REGISTER_ENCOURAGEMENT_DIALOG;
const UpgradeEncouragementDialog =
  process.env.NEXT_PUBLIC_PIANO_TEMPLATE_UPGRADE_ENCOURAGEMENT_DIALOG;

const FullyLockedRegistrationWall =
  process.env.NEXT_PUBLIC_PIANO_TEMPLATE_FULLY_LOCKED_REGISTRATION_WALL;
const PartiallyLockedRegistrationWall =
  process.env.NEXT_PUBLIC_PIANO_TEMPLATE_PARTIALLY_LOCKED_REGISTRATION_WALL;
const FreeRegistrationWall =
  process.env.NEXT_PUBLIC_PIANO_TEMPLATE_FREE_REGISTRATION_WALL;

export const PianoPlaceholderTemplateId = {
  FreeRegistrationWall,
  FullyLockedRegistrationWall,
  PartiallyLockedRegistrationWall,
  RegisterEncouragementDialog,
  UpgradeEncouragementDialog
} as const;

export const PianoMountingId = {
  Dialog: 'piano-dialog',
  InternalDialog: 'piano-dialog-internal',
  InternalModal: 'piano-modal-internal',
  Modal: 'piano-modal'
} as const;

export const PianoEventName = {
  ModalClose: 'pianoModalClose',
  SetResponseVariable: 'setResponseVariable',
  ShowTemplate: 'showTemplate'
} as const;

/* This container is purposed for the internal react managed dialog in order to prevent interference with the piano library tied container */
export const PIANO_DIALOG_DOM_ID_INTERNAL = 'piano-dialog-internal';
export const PIANO_DIALOG_DOM_ID = 'piano-dialog';

/* This container is purposed for the internal react managed modal in order to prevent interference with the piano library tied container */
export const PIANO_MODAL_DOM_ID_INTERNAL = 'piano-modal-internal';
export const PIANO_MODAL_DOM_ID = 'piano-modal';
