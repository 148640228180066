import type { PropsWithChildren } from 'react';

import type {
  TrackingTriggerProps,
  TrackingProviderProps,
  TrackingTriggerPayload
} from './event-tracker/types';
import { TrackingProvider, TrackingTrigger } from './event-tracker';

export { AnalyticsDefaultTrigger } from './analytics-provider.core';
export { TrackingContext as AnalyticsContext } from './event-tracker';
export type { TrackingTriggerPayload };

/* Our default payload */
export const AnalyticsDefaultPayload = {
  eventId: '',
  props: {
    domain: 'ouside-inc'
  }
};

/* Our default options */
export const AnalyticsDefaultOptions = {
  activeDestinations: [],
  analyticsJsUrl: '', // Necessary
  writeKey: '' // Necessary
};

export const AnalyticsTrigger = ({
  children,
  event,
  options,
  payload
}: PropsWithChildren<TrackingTriggerProps>) => (
  <TrackingTrigger {...{ event, options, payload }}>{children}</TrackingTrigger>
);

export const AnalyticsProvider = ({
  children,
  options,
  payload,
  trigger
}: PropsWithChildren<TrackingProviderProps>) => (
  <TrackingProvider options={options} payload={payload} trigger={trigger}>
    {children}
  </TrackingProvider>
);
