import {
  PREVIEW_HEIGHT,
  PREVIEW_WIDTH,
  Y_OFFSET
} from 'helpers/constants/posterPreview';
import type { MutableRefObject } from 'react';
import { useState, useEffect } from 'react';

type Inputs = {
  ref: MutableRefObject<HTMLElement>;
  shouldRecalculate: boolean;
};

const getRoundedClientRect = (rect: DOMRect): DOMRect => {
  if (!rect) return null;

  const { bottom, height, left, right, toJSON, top, width, x, y } = rect;

  return {
    bottom: Math.round(bottom),
    height,
    left: Math.round(left),
    right: Math.round(right),
    toJSON,
    top: Math.round(top),
    // DOMRect is non-spreadable, so we have to just copy that
    width,
    x,
    y
  };
};

export const usePosterPreview = ({ ref, shouldRecalculate }: Inputs) => {
  const [position, setPosition] = useState(null);
  const [previewWidth, setPreviewWidth] = useState(null);
  const [previewHeight, setPreviewHeight] = useState(null);

  useEffect(() => {
    if (!ref.current && shouldRecalculate) return;

    const clientRect = getRoundedClientRect(
      ref.current?.getBoundingClientRect()
    );

    if (!clientRect) return;

    const calculatedPreviewWidth =
      PREVIEW_WIDTH - clientRect.width * 1.25 < 0
        ? clientRect.width * 1.25
        : PREVIEW_WIDTH;
    setPreviewWidth(calculatedPreviewWidth);

    const calculatedPreviewHeight =
      clientRect.height > PREVIEW_HEIGHT ? clientRect.height : PREVIEW_HEIGHT;
    setPreviewHeight(calculatedPreviewHeight);

    const xOffset = calculatedPreviewWidth - clientRect.width;
    let stickToTheLeft = clientRect.left + window.scrollX;
    let stickToTheRight = stickToTheLeft - xOffset;

    let isLeftEdgeElement = clientRect.left < 100;
    let isRightEdgeElement = window.innerWidth - clientRect.right < 100;

    // different positioning when inside Embla carousel
    const container = ref.current.closest('.embla');
    if (container) {
      const containerRect = getRoundedClientRect(
        container.getBoundingClientRect()
      );
      isLeftEdgeElement = containerRect.left >= clientRect.left;
      if (isLeftEdgeElement) {
        stickToTheLeft = containerRect.left + window.scrollX;
      }
      isRightEdgeElement = containerRect.right <= clientRect.right;
      if (isRightEdgeElement) {
        stickToTheRight = containerRect.right - calculatedPreviewWidth;
      }
    }

    const centeredWithOffsets = stickToTheLeft - xOffset / 2;

    const getLeftPosition = () => {
      if (isLeftEdgeElement) return stickToTheLeft;
      if (isRightEdgeElement) return stickToTheRight;
      return centeredWithOffsets;
    };

    const top = clientRect.top + window.scrollY - Y_OFFSET;
    const left = getLeftPosition();

    setPosition({
      left,
      top
    });
  }, [ref, shouldRecalculate]);

  return { position, previewHeight, previewWidth };
};
