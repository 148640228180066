import { useQuery } from '@tanstack/react-query';

import { getBucketList } from './queryFunctions';

export const BUCKET_LIST_KEY = 'BucketList';

export const useBucketList = () =>
  useQuery({
    placeholderData: {
      eventsBucketList: []
    },
    queryFn: getBucketList,
    queryKey: [BUCKET_LIST_KEY]
  });
