import Icon from 'components/01-atoms/Icon';
import TrackingLink from 'components/02-molecules/TrackingLink';
import { routes } from 'lib/routes';

const SearchLink = () => (
  <TrackingLink
    analytics={{
      name: 'Search icon',
      placement: 'header'
    }}
    classes="!flex hover:bg-white/20 rounded-full justify-center items-center w-10 h-10 focus-visible:bg-white/20 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus"
    href={routes.search()}
    title="Search"
  >
    <Icon className="text-white" type="magnifier" width={18} />
  </TrackingLink>
);

export default SearchLink;
