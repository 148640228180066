import { useState, useCallback } from 'react';

type Output = [
  boolean,
  {
    setFalse: VoidFunction;
    setTrue: VoidFunction;
    toggle: VoidFunction;
  }
];

export const useToggle = (initialState = false): Output => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((prevState) => !prevState), []);

  const setTrue = useCallback(() => setState(true), []);

  const setFalse = useCallback(() => setState(false), []);

  return [state, { setFalse, setTrue, toggle }];
};
