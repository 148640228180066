import Box from 'components/01-atoms/Box';
import Heading from 'components/01-atoms/Heading';

type Props = {
  activeTitle: string;
};

const ActivePageTitle = ({ activeTitle }: Props) => (
  <Box className="flex h-16 w-full items-center justify-center bg-new-neutral-900 pb-2 mb-4">
    <Heading
      classes="text-network-white"
      level={1}
      text={activeTitle}
      variant="heading2"
    />
  </Box>
);

export default ActivePageTitle;
