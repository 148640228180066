import ScoutLink from 'components/01-atoms/ScoutLink';
import SearchLink from 'components/01-atoms/SearchLink';

const HeaderIcons = () => (
  <div className="flex justify-between narrow-x:justify-stretch pl-1 w-full">
    <SearchLink />
    <ScoutLink />
  </div>
);

export default HeaderIcons;
