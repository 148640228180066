import type { ReactNode } from 'react';
import React, { useState } from 'react';
import {
  PIANO_STORE_DEFAULT_VALUES,
  PianoStoreContext,
  PianoStoreUpdaterContext
} from 'contexts/PianoStoreContext';

type Props = {
  children: ReactNode;
};

export const PianoStoreProvider = ({ children }: Props) => {
  const [store, setStore] = useState(PIANO_STORE_DEFAULT_VALUES);

  return (
    <PianoStoreUpdaterContext.Provider value={setStore}>
      <PianoStoreContext.Provider value={store}>
        {children}
      </PianoStoreContext.Provider>
    </PianoStoreUpdaterContext.Provider>
  );
};
