import type { AuthService } from '@pocketoutdoormedia/auth-state-service';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

export const useLDClientIdentify = (authService: AuthService) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (authService.userState.isAuthenticated && ldClient) {
      const { userData } = authService.userState;

      const userName =
        userData.firstName && userData.lastName
          ? `${userData.firstName} ${userData.lastName}`
          : userData.email;

      ldClient.identify({
        email: userData.email,
        key: userData.email,
        kind: 'user',
        name: userName
      });
    }
  }, [authService.userState, ldClient]);
};
