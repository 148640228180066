import { useState } from 'react';
import type { ReactNode, ReactElement } from 'react';
import Portal from 'components/02-molecules/Portal';
import ScrollLocked from 'components/01-atoms/ScrollLocked';
import { PortalRendererUpdaterContext } from 'contexts/PortalRendererContext';

type Props = {
  children: ReactNode;
};

export const RENDERER_DOM_ID = 'portal-renderer';

const PortalRendererContextProvider = ({ children }: Props) => {
  const [Component, renderComponent] = useState<ReactElement | null>(null);

  return (
    <PortalRendererUpdaterContext.Provider value={renderComponent}>
      {children}
      {Component && (
        <Portal id={RENDERER_DOM_ID}>
          <ScrollLocked>{Component}</ScrollLocked>
        </Portal>
      )}
    </PortalRendererUpdaterContext.Provider>
  );
};

export default PortalRendererContextProvider;
