import Link from 'next/link';

export type NavigationLinkProps = {
  /** Optional children to be rendered in the anchor tag. */
  children?: React.ReactNode;
  classes?: string;
  href: string;
  /** Display label also used for accessibility. */
  label: string;
  /** Optional function called on link click. */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  target?: '_blank' | '_parent' | '_self' | '_top';
};

export const NavigationLink = ({
  children,
  classes,
  href,
  label,
  onClick,
  target = '_self'
}: NavigationLinkProps) => (
  <Link
    aria-label={label}
    className={classes}
    href={href}
    onClick={onClick}
    prefetch={false}
    target={target}
  >
    {children || label}
  </Link>
);
