import type { KeyboardEventHandler } from 'react';
import { useCallback } from 'react';

export const useHandleKeyDown = (
  keyName: KeyboardEvent['key'],
  callback?: VoidFunction
) =>
  useCallback<KeyboardEventHandler>(
    (event) => {
      if (event.key !== keyName) return;
      callback?.();
    },
    [callback, keyName]
  );
