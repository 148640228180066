import { routes } from 'lib/routes';
import type { EventsBucketSummary } from 'helpers/types/jwplayer-subgraph';

export const parseBucketList = (bucketList: Nullable<EventsBucketSummary[]>) =>
  bucketList?.map((el: EventsBucketSummary) => ({
    href: routes.eventBucket(el.slug),
    matchers: [`/live-events/${el.slug}`],
    slug: el.slug,
    target: '_self' as string,
    text: el.title
  }));
