import type { NextRouter } from 'next/router';
import { BFF_LOGIN_PATH } from 'helpers/middleware/constants';

import type {
  RawUserPreferences,
  PreferencesFromFeedSettings,
  UserPreference
} from './types';

const pattern = /=(.*)$/;

export const redirectToSignIn = (
  key: string,
  router: NextRouter,
  additionalSearchParams: Record<string, string> = {}
) => {
  const match = key.match(pattern);
  const sesame = match[1];

  const originUrl = new URL(window.location.toString());

  Object.entries(additionalSearchParams).forEach(([name, value]) =>
    originUrl.searchParams.set(name, value)
  );

  router.push({
    pathname: BFF_LOGIN_PATH,
    query: {
      origin_uri: originUrl.toString(),
      regwall: true,
      sesame
    }
  });
};

export const getPreferencesFromFeedSettings = (
  feedSettings: RawUserPreferences
): PreferencesFromFeedSettings =>
  [...feedSettings.activities, ...feedSettings.topics].reduce(
    (acc, item: UserPreference) => {
      if (item.selected) {
        acc.preferences.push(item.name);
      }
      item.children?.forEach((child: UserPreference) => {
        if (child.selected) {
          acc.sub_preferences.push(`${item.name}.${child.name}`);
        }
      });
      return acc;
    },
    {
      preferences: [],
      sub_preferences: []
    }
  );
