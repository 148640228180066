import {
  PIANO_DIALOG_DOM_ID,
  PIANO_MODAL_DOM_ID
} from '../../helpers/constants/piano';

const PIANO_TARGET_DOM_ID = {
  dialog: PIANO_DIALOG_DOM_ID,
  modal: PIANO_MODAL_DOM_ID
};
export type PianoPayloadTarget = keyof typeof PIANO_TARGET_DOM_ID;
export type RemoteTemplateAction = 'close';
export type PianoMessagePayload =
  | { requestAction: RemoteTemplateAction; type: 'REQUEST_REMOTE_ACTION' }
  | { timeLeft: number; type: 'WATCH_TIME_COUNTING_UPDATE' };

export const sendToPianoTemplate = (
  payload: PianoMessagePayload,
  target: PianoPayloadTarget
) => {
  const pianoTargetIframe: HTMLIFrameElement = document.querySelector(
    `#${PIANO_TARGET_DOM_ID[target]} iframe`
  );

  if (pianoTargetIframe) {
    pianoTargetIframe.contentWindow.postMessage(payload, '*');
  }
};

export const sendRemoteActionToPianoTemplate = (
  requestAction: RemoteTemplateAction,
  target: PianoPayloadTarget
) =>
  sendToPianoTemplate({ requestAction, type: 'REQUEST_REMOTE_ACTION' }, target);

export const sendTimeCountingUpdate = (
  timeLeft: number,
  target: PianoPayloadTarget
) =>
  sendToPianoTemplate({ timeLeft, type: 'WATCH_TIME_COUNTING_UPDATE' }, target);
