import { GraphQLClient } from 'graphql-request';
import { getApolloGraphqlHeaders } from 'helpers/utils/api/getApolloGraphqlHeaders';

import { errorResponseMiddleware } from './middleware/errorResponseMiddleware';

// TODO: better test env handling
const isTest = process.env.NODE_ENV === 'test';

const testUrl = 'https://stage-api.outsideapi.com/fed-gw/graphql';

const endpoint = isTest ? testUrl : process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT;

export const client = new GraphQLClient(endpoint, {
  errorPolicy: 'all',
  headers: {
    ...getApolloGraphqlHeaders()
  },
  responseMiddleware: errorResponseMiddleware
});
