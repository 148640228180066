import { useState } from 'react';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { getQueryClient } from 'lib/reactQuery';
import type { ReactNode } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

type Props = {
  children: ReactNode;
  dehydratedState?: Record<string, unknown>;
};

const ReactQueryProvider = ({ children, dehydratedState }: Props) => {
  const [queryClient] = useState(getQueryClient);
  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>{children}</HydrationBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
