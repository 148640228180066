import { useQuery } from '@tanstack/react-query';
import type { QueryClient } from '@tanstack/react-query';

import { getMarketing } from './queryFunctions';

export const MARKETING_QUERY_KEY = 'marketing';

export const useMarketing = () =>
  useQuery({
    queryFn: getMarketing,
    queryKey: [MARKETING_QUERY_KEY],
    retry: 3
  });

export const prefetchMarketing = (queryClient: QueryClient) => {
  queryClient.prefetchQuery({
    queryFn: getMarketing,
    queryKey: [MARKETING_QUERY_KEY]
  });
};
