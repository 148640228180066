import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';
import Helmet from 'react-helmet';
import clsx from 'clsx';
import { parseCategoryList } from 'helpers/utils/parseCategoryList';
import { useCategoryList } from 'apis/category/queries';
import { useBucketList } from 'apis/eventBucket/queries';
import { parseBucketList } from 'helpers/utils/parseBucketList';
import { useRouter } from 'next/router';
import { useScrollDirection } from 'helpers/hooks/useScrollDirection';
import { getClearPath } from 'helpers/utils/url/getClearPath';

import useHeaderAnalyticsListeners from './hooks/useHeaderAnalyticsListeners';
import { createNavLinks, getPageTitle } from './utils';
import NavigationListItem from './components/NavigationListItem';
import ActivePageTitle from './components/ActivePageTitle';
import { SUBNAVIGATION_SHIFT_CLASSES } from './constants';

type Props = {
  /** Aria label for inner nav bar */
  ariaLabel: string;
  /** Current location pathname, if it matches a link's href it will display a yellow underline indicating that it's active. */
  currentPath?: string;
  /** If true, refersion tracking is used. */
  useRefersion?: boolean;
};

const WatchSubNavigation = ({
  ariaLabel,
  currentPath,
  useRefersion = false
}: Props) => {
  const { stripeId } = useHeaderAnalyticsListeners();
  const { isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow-x');
  const { data: categoriesListData } = useCategoryList();
  const { data: bucketListData } = useBucketList();
  const { asPath, pathname, query } = useRouter();

  const links = createNavLinks({
    bucketList: parseBucketList(bucketListData?.eventsBucketList),
    categoriesList: parseCategoryList(categoriesListData?.watchCategoryList)
  });

  const pageTitle = getPageTitle(
    links,
    pathname,
    query?.categoryTitle as string
  );

  const scrollDirection = useScrollDirection();

  const isHidden = scrollDirection === 'down';

  return (
    <header
      className={clsx(
        'sticky top-[60px] md:top-[68px] z-20 w-full bg-new-neutral-900',
        {
          [SUBNAVIGATION_SHIFT_CLASSES.default]: isHidden && !pageTitle,
          [SUBNAVIGATION_SHIFT_CLASSES.none]: !isHidden,
          [SUBNAVIGATION_SHIFT_CLASSES.withPageTitle]: isHidden && pageTitle,
          'pt-0 px-0': isMobile
        }
      )}
    >
      <div className="container mx-auto">
        {useRefersion && stripeId && (
          <Helmet>
            <script>
              {`
             _refersion(function(){ _rfsn._setSource('STRIPE'); _rfsn._addCart('${stripeId}'); });
            `}
            </script>
          </Helmet>
        )}
        <div className="flex items-center justify-center w-full h-13 narrow-x:h-19">
          <nav aria-label={ariaLabel}>
            <ul className="flex text-center gap-2 h-full">
              {links.map((link) => (
                <NavigationListItem
                  key={link.text}
                  currentPath={currentPath}
                  exactPath={getClearPath(asPath)}
                  isNavHidden={isHidden}
                  link={link}
                />
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {pageTitle && <ActivePageTitle activeTitle={pageTitle} />}
    </header>
  );
};

export default WatchSubNavigation;
