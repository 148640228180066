import { NavigationLink } from 'components/02-molecules/NavigationLink';
import type { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Icon from 'components/01-atoms/Icon';
import { useHover } from 'helpers/hooks/useHover';

import type { NavLinkProps, onMenuItemCLick, onNavLinkClick } from '../types';

const commonLinkClasses =
  'flex h-full items-center font-plus-jakarta-sans text-12 narrow:text-16 px-2 narrow:px-3 py-2 hover:text-btn-secondary-hover active:text-btn-secondary-active focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus';

type Props = {
  handleNavLinkClick: onMenuItemCLick | onNavLinkClick;
  hasSubmenu: boolean;
  isActive: boolean;
  link: NavLinkProps;
};

const HeaderNavLink = ({
  children,
  handleNavLinkClick,
  hasSubmenu,
  isActive,
  link
}: PropsWithChildren<Props>) => {
  const { classes, href, text } = link;
  const [isHover, handlers] = useHover();
  const shouldBeClickableLink = href !== '#';

  if (shouldBeClickableLink) {
    return (
      <NavigationLink
        classes={clsx(commonLinkClasses, classes, {
          'font-semibold': !!href,
          'text-btn-secondary-hover': isActive,
          'text-network-white': !isActive
        })}
        href={href}
        label={text}
        onClick={handleNavLinkClick as onNavLinkClick}
      >
        {children}
      </NavigationLink>
    );
  }

  return (
    <button
      {...handlers}
      aria-label={text}
      className={clsx(commonLinkClasses, classes, {
        'font-semibold': !!href,
        'text-btn-secondary-hover': isActive,
        'text-network-white': !isActive
      })}
      onClick={handleNavLinkClick as onMenuItemCLick}
      type="button"
    >
      <span className="relative h-full flex items-center">
        {children}
        {hasSubmenu ? (
          <Icon
            className={clsx('mx-1 transition-transform', {
              'text-btn-secondary-hover': isHover || isActive,
              'text-network-white': !isHover && !isActive
            })}
            type="chevronDown"
            width={14}
          />
        ) : null}
      </span>
    </button>
  );
};

export default HeaderNavLink;
