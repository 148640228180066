import type { ComponentType } from 'react';
import type { ProviderConfig } from 'launchdarkly-react-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ANONYMOUS_USER_ID } from 'helpers/constants';

const config: ProviderConfig = {
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID,
  context: {
    anonymous: true,
    key: ANONYMOUS_USER_ID,
    kind: 'user'
  },
  options: {
    bootstrap: 'localStorage'
  }
};

export const withLaunchDarkly = <P extends object>(
  Component: ComponentType<P>
) => withLDProvider<P>(config)(Component);
