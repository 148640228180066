import { client } from 'lib/graphqlRequest';
import { generateCookieHeader } from 'helpers/utils/generateCookieHeader';
import type { QueryWatchCategoryArgs } from 'helpers/types/jwplayer-subgraph';

import { GET_CATEGORY, GET_CATEGORY_LIST } from './graphql';
import type {
  GetCategoryListResponse,
  GetCategoryParams,
  GetCategoryResponse
} from './types';

export const getCategoryList = () =>
  client.request<GetCategoryListResponse>(GET_CATEGORY_LIST);

export const getCategory = ({ cookie, slug }: GetCategoryParams) =>
  client.request<GetCategoryResponse, QueryWatchCategoryArgs>(
    GET_CATEGORY,
    { slug },
    generateCookieHeader(cookie)
  );
