/* eslint-disable camelcase */
import { useContext, useCallback } from 'react';

import { AnalyticsContext } from './analytics-provider';
import type {
  TrackTriggerPayload,
  IdentifyTriggerPayload,
  PageTriggerPayload,
  ElementClickedData,
  ElementObservedData,
  PurchaseData,
  PostData,
  PostClickedData,
  RegistrationErrorData,
  BaseAdAnalyticsPayload,
  BasePlaybackAnalyticsPayload,
  FullscreenOpenedPayload,
  RegWallViewedPayload,
  UserSignedPayload
} from './types';

export const useAnalyticsContext = () => {
  const tracker = useContext(AnalyticsContext);
  // eslint-disable-next-line no-underscore-dangle
  const options = tracker._data?.options;

  const track = useCallback(
    (props: TrackTriggerPayload) =>
      tracker.trigger && tracker.trigger('track', props),
    [tracker]
  );

  const identify = useCallback(
    ({ eventId, props }: IdentifyTriggerPayload) =>
      tracker.trigger && tracker.trigger('identify', { eventId, props }),
    [tracker]
  );

  const page = useCallback(
    ({ path, title, url, ...props }: PageTriggerPayload) =>
      tracker.trigger &&
      tracker.trigger('page', { path, title, url, ...props }),
    [tracker]
  );

  const elementClicked = useCallback(
    ({ name, type = 'button', url, ...props }: ElementClickedData) => {
      track({
        eventId: 'Element Clicked',
        props: {
          destination_url: url,
          name,
          type,
          ...props
        }
      });
    },
    [track]
  );

  const videoAdImpression = useCallback(
    (payload: BaseAdAnalyticsPayload) => {
      track({
        eventId: 'Video Ad Impression',
        props: payload
      });
    },
    [track]
  );

  const videoAdCompleted = useCallback(
    (payload: BaseAdAnalyticsPayload) => {
      track({
        eventId: 'Video Ad Completed',
        props: payload
      });
    },
    [track]
  );

  const videoAdClicked = useCallback(
    (payload: BaseAdAnalyticsPayload) => {
      track({
        eventId: 'Video Ad Clicked',
        props: payload
      });
    },
    [track]
  );

  const videoPaused = useCallback(
    (payload: BasePlaybackAnalyticsPayload) => {
      track({
        eventId: 'Video Paused',
        props: payload
      });
    },
    [track]
  );

  const videoPlayed = useCallback(
    (payload: BasePlaybackAnalyticsPayload) => {
      track({
        eventId: 'Video Played',
        props: payload
      });
    },
    [track]
  );

  const videoCompleted = useCallback(
    (payload: BasePlaybackAnalyticsPayload) => {
      track({
        eventId: 'Video Completed',
        props: payload
      });
    },
    [track]
  );

  const videoStarted = useCallback(
    (payload: BasePlaybackAnalyticsPayload) => {
      track({
        eventId: 'Video Started',
        props: payload
      });
    },
    [track]
  );

  const videoQuartileTwentyFive = useCallback(
    (payload: BasePlaybackAnalyticsPayload) => {
      track({
        eventId: 'Video Quartile 25',
        props: payload
      });
    },
    [track]
  );

  const videoQuartileFifty = useCallback(
    (payload: BasePlaybackAnalyticsPayload) => {
      track({
        eventId: 'Video Quartile 50',
        props: payload
      });
    },
    [track]
  );

  const videoQuartileSeventyFive = useCallback(
    (payload: BasePlaybackAnalyticsPayload) => {
      track({
        eventId: 'Video Quartile 75',
        props: payload
      });
    },
    [track]
  );

  const videoMute = useCallback(
    (payload: BasePlaybackAnalyticsPayload) => {
      track({
        eventId: 'Video Volume',
        props: payload
      });
    },
    [track]
  );

  const elementObserved = useCallback(
    ({ name, type, ...props }: ElementObservedData) => {
      track({
        eventId: 'Element Observed',
        props: {
          name,
          type,
          ...props
        }
      });
    },
    [track]
  );

  const registrationCompleted = useCallback(() => {
    track({
      eventId: 'Registration Completed',
      props: {}
    });
  }, [track]);

  const registrationErrorsReceived = useCallback(
    ({ error_messages, step_name, step_number }: RegistrationErrorData) => {
      track({
        eventId: 'Registration Errors Received',
        props: {
          error_messages,
          step_name,
          step_number
        }
      });
    },
    [track]
  );

  const purchaseCompleted = useCallback(
    ({
      category,
      membershipSubscription,
      paymentFrequency,
      revenue,
      type,
      value,
      ...props
    }: PurchaseData) => {
      track({
        eventId: 'Purchase Completed',
        props: {
          category,
          membership_subscription: membershipSubscription,
          payment_frequency: paymentFrequency,
          revenue,
          type,
          value,
          ...props
        }
      });
    },
    [track]
  );

  const postViewed = useCallback(
    (props: PostData) => {
      track({
        eventId: 'Post Viewed',
        props
      });
    },
    [track]
  );

  const postClicked = useCallback(
    ({
      dataSource,
      listingType,
      location,
      path,
      title,
      ...props
    }: PostClickedData) => {
      track({
        eventId: 'Post Clicked',
        props: {
          data_source: dataSource,
          listing_type: listingType,
          location,
          path,
          title,
          ...props
        }
      });
    },
    [track]
  );

  const fullScreenOpened = useCallback(
    ({ name, ...props }: FullscreenOpenedPayload) => {
      track({
        eventId: 'Fullscreen opened',
        props: {
          name,
          ...props
        }
      });
    },
    [track]
  );

  // watch-regwall
  const regwallViewed = useCallback(
    (props: RegWallViewedPayload) => {
      track({
        eventId: 'RegWall Viewed',
        props
      });
    },
    [track]
  );

  // watch-regwall
  const userSignedIn = useCallback(
    (props: UserSignedPayload) => {
      track({
        eventId: 'User Signed In',
        props
      });
    },
    [track]
  );

  // watch-regwall
  const userSignedUp = useCallback(
    (props: UserSignedPayload) => {
      track({
        eventId: 'User Signed Up',
        props
      });
    },
    [track]
  );

  return {
    elementClicked,
    elementObserved,
    fullScreenOpened,
    identify,
    options,
    page,
    postClicked,
    postViewed,
    purchaseCompleted,
    registrationCompleted,
    registrationErrorsReceived,
    regwallViewed,
    tracker,
    userSignedIn,
    userSignedUp,
    videoAdClicked,
    videoAdCompleted,
    videoAdImpression,
    videoCompleted,
    videoMute,
    videoPaused,
    videoPlayed,
    videoQuartileFifty,
    videoQuartileSeventyFive,
    videoQuartileTwentyFive,
    videoStarted
  };
};
