import { useState, useRef, useEffect } from 'react';

type Options = {
  pageOffset: number;
};

type ScrollDirection = 'down' | 'up';

const DEFAULT_PAGE_OFFSET = 200;

const useScrollDirection = (
  options: Options = {
    pageOffset: DEFAULT_PAGE_OFFSET
  }
): ScrollDirection => {
  const { pageOffset } = options;
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>('up');

  const blocking = useRef(false);
  const previousPosition = useRef(0);

  useEffect(() => {
    previousPosition.current = window.scrollY;

    const updateScrollDirection = () => {
      const { scrollY } = window;

      if (scrollY > DEFAULT_PAGE_OFFSET && scrollY > previousPosition.current) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }

      previousPosition.current = scrollY;
      blocking.current = false;
    };

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true;
        window.requestAnimationFrame(updateScrollDirection);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [pageOffset, scrollDirection]);

  return scrollDirection;
};

export { useScrollDirection };
