import {
  LIVE_STREAM_24_ID,
  ACCOUNTS_OUTSIDE_ONLINE_URLS
} from 'helpers/constants';

export const routes = {
  bookmarks: () => ACCOUNTS_OUTSIDE_ONLINE_URLS.BOOKMARKS,
  categories: () => '/categories',
  categoriesDetail: (categoryName: string) =>
    `/categories/${categoryName.toLowerCase()}`,
  episodeDetail: (seriesTitle: string, episodeId: string) =>
    `/series/${seriesTitle}/episode/${episodeId}`,
  eventBucket: (slug: string) => `/live-events/${slug}`,
  eventDetail: (slug: string, id: string) => `/live-events/${slug}/${id}`,
  feed: () => '/feed',
  filmDetail: (title: string, mediaId: string) => `/${title}/${mediaId}`,
  films: () => '/films',
  home: () => '/',
  liveEvents: () => '/live-events',
  liveStream24: (slug: string) => `/live-events/${slug}/${LIVE_STREAM_24_ID}`,
  localChannel: (slug: string, id: string) => `/local-channels/${slug}/${id}`,
  memberExclusives: () => '/member_exclusives',
  myBenefits: () => '/my_benefits',
  page404: () => '/404',
  page500: () => '/500',
  search: () => '/search',
  series: () => '/series',
  seriesDetail: (title: string, id: string) => `/series/${title}/${id}`,
  shorts: () => '/shorts',
  shortsDetail: (title: string, mediaId: string) =>
    `/shorts/${title}/${mediaId}`,
  temporaryEventDetail: (id: string) => `/live-event/${id}` // This route was made only as a temporary solution for recaps&replays and upcoming live events carousel
} as const;
