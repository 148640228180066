import { gql } from 'graphql-request';

export const TRAILER_MEDIA_ITEM_FRAGMENT = gql`
  fragment TrailerMediaItemFragment on MediaItem {
    category
    description
    duration
    filmDuration
    filmId
    filmMediaItem {
      meterFlow
    }
    images {
      src
      type
      width
    }
    isFreeContent
    location
    mediaId
    meterFlow
    poster
    productionYear
    seriesId
    seriesCount {
      count
      type
    }
    sources {
      file
    }
    tags
    title
  }
`;

export const USER_PROGRESS_FRAGMENT = gql`
  fragment UserProgressFragment on MediaItem {
    userProgress {
      progress
      isFinished
      timestamp
      timeLeft
    }
  }
`;

export const SERIES_PROGRESS_FRAGMENT = gql`
  fragment SeriesProgressFragment on MediaItem {
    seriesProgress {
      episode
      episodeMedia {
        duration
        title
        description
        tags
        userProgress {
          progress
          isFinished
          timestamp
          timeLeft
        }
      }
      episodeProgress {
        isFinished
        progress
        timeLeft
        timestamp
      }
      season
    }
  }
`;

export const MEDIA_ITEM_FRAGMENT = gql`
  fragment MediaItemFragment on MediaItem {
    associatedFilmId
    associatedTrailerId
    category
    contentType
    creator
    creditTimestamp
    description
    duration
    episodeMeta {
      episode
      season
    }
    feedId
    filmDuration
    filmId
    filmMediaItem {
      isDrmProtected
      mediaId
      duration
      meterFlow
      userProgress {
        progress
        isFinished
        timestamp
        timeLeft
      }
    }
    images {
      src
      type
      width
    }
    isAdsFree
    isDrmProtected
    isFreeContent
    liveState
    location
    mediaId
    meterFlow
    modifiedTime
    poster
    productionYear
    publishedTime
    querystring
    scheduledStart
    seriesCount {
      count
      type
    }
    seriesId
    seriesMeterflow
    seriesSlug
    tags
    title
    trailerMediaItem {
      ...TrailerMediaItemFragment
    }
  }
  ${TRAILER_MEDIA_ITEM_FRAGMENT}
`;
