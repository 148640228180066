import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { NextRouter } from 'next/router';

import type {
  ExtendedRawUserProfile,
  OneTimePasswordPayload,
  RawUserPreferences,
  RivtRegistrationFormData
} from './types';
import { getPreferencesFromFeedSettings, redirectToSignIn } from './helpers';

const API_KEY = process.env.NEXT_PUBLIC_WATCH_RIVT_API_KEY;
const RIVT_HOST = process.env.NEXT_PUBLIC_RIVT_HOST;

export const createUser = async (formData: RivtRegistrationFormData) =>
  axios.post(process.env.NEXT_PUBLIC_RIVT_SHORT_REGISTRATION_URL, formData, {
    headers: {
      Accept: 'application/json',
      'Api-Key': API_KEY,
      'Content-Type': 'application/json'
    }
  });

export const getUserExists = async (email: string) => {
  try {
    await createUser({
      email,
      password1: '',
      password2: ''
    });
    // This query should always throw since password is too weak. Return value here is for type safety
    return true;
  } catch (error) {
    if (!error.isAxiosError) throw error;
    const responseData = error.response.data;
    // If there is an error regarding email address it means we got an error saying that user already exists
    return !!responseData.details.email;
  }
};

export const getOneTimePassword = async (payload: OneTimePasswordPayload) =>
  axios.post(process.env.NEXT_PUBLIC_OTP_URL, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

export const signIn = async (
  credentials: OneTimePasswordPayload,
  router: NextRouter,
  additionalRedirectSearchParams: Record<string, string> = {}
) => {
  const otpResponse = await getOneTimePassword(credentials);
  redirectToSignIn(otpResponse.data.key, router, {
    mode: 'fullscreen',
    ...additionalRedirectSearchParams
  });
};

export const signUp = async (
  credentials: RivtRegistrationFormData,
  router: NextRouter
) => {
  await createUser(credentials);
  await signIn(
    { email: credentials.email, password: credentials.password1 },
    router,
    { firstSignIn: 'true' }
  );
};

export const getUserProfile = async (
  rivtToken: string
): Promise<AxiosResponse<ExtendedRawUserProfile>> =>
  axios.get(`${RIVT_HOST}/user/short-profile`, {
    headers: {
      'Api-Key': API_KEY,
      Authorization: `Bearer ${rivtToken}`
    }
  });

export const getUserPreferences = async (
  rivtToken: string
): Promise<AxiosResponse<RawUserPreferences>> =>
  axios.get(`${RIVT_HOST}/user/settings/feed`, {
    headers: {
      'Api-Key': API_KEY,
      Authorization: `Bearer ${rivtToken}`
    }
  });

export const getCompleteAuthData = async (
  rivtToken: string
): Promise<ExtendedRawUserProfile> => {
  const userProfile = await getUserProfile(rivtToken);
  const userPreferences = await getUserPreferences(rivtToken);
  // eslint-disable-next-line camelcase
  const { preferences, sub_preferences } = getPreferencesFromFeedSettings(
    userPreferences.data
  );

  // eslint-disable-next-line camelcase
  return { preferences, sub_preferences, ...userProfile.data };
};
