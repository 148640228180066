import CookieConsent from './CookieConsent';
import Piano from './Piano';

const Scripts = () => (
  <>
    <Piano />
    <CookieConsent />
  </>
);

export default Scripts;
