import Script from 'next/script';

const COOKIE_CONSENT_SDK_URL =
  'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk-loader.js';
const SECURITI_BACKEND_URL = 'https://app.securiti.ai';

const CookieConsent = () => {
  if (
    !process.env.NEXT_PUBLIC_SECURITI_TENANT_UUID ||
    !process.env.NEXT_PUBLIC_SECURITI_DOMAIN_UUID
  ) {
    // TODO: move this into error-tracking service
    // eslint-disable-next-line no-console
    console.error(`Securiti script has failed - missing required env variable`);
    return null;
  }

  return (
    <Script id="cookie-consent">
      {`
        (() => {
          // Custom event listener for consent given.
          window.s__c = true;
          window.addEventListener('message', (ev) => {
            if (ev.data.message === 'consent_given') {
              window.s__c_g = ev.data.consentStatuses;
            }
          });
          document.body.addEventListener('click', (ev) => {
            const isCookieButton = ['cc-allow', 'cmp-revoke-consent'].some(cls => ev.target.classList.contains(cls));
            if (isCookieButton) {
              if (!window.s__c_i_g) {
                // Let all event listeners fire first.
                setTimeout(() => {
                  window.s__c_i_g = true;
                }, 1);
              }
            }
          })
          // Securiti default.
          var s = document.createElement('script');
          s.src = '${COOKIE_CONSENT_SDK_URL}';
          s.setAttribute('data-tenant-uuid', '${process.env.NEXT_PUBLIC_SECURITI_TENANT_UUID}');
          s.setAttribute('data-domain-uuid', '${process.env.NEXT_PUBLIC_SECURITI_DOMAIN_UUID}');
          s.setAttribute('data-backend-url', '${SECURITI_BACKEND_URL}');
          s.setAttribute('data-skip-css', 'false');
          s.defer = true;
          var parent_node = document.head || document.body;
          parent_node.appendChild(s);
          s.addEventListener('load', () => {
            window.initCmp();
          });
        })();
      `}
    </Script>
  );
};

export default CookieConsent;
