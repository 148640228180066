import { Header as GlobalHeader } from '@pocketoutdoormedia/global-header';
import MainLogo from 'components/02-molecules/MainLogo';
import { globalHeaderNavigationConfig } from 'lib/globalHeader';
import { getUserState } from '@pocketoutdoormedia/auth-state-service';
import { getCookies } from 'cookies-next';
import HeaderIcons from 'components/02-molecules/HeaderIcons';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Header = () => {
  const cookies = getCookies();
  const userState = getUserState(cookies);
  const { accountsEmailVerification } = useFlags();

  return (
    <GlobalHeader
      logo={<MainLogo />}
      navigation={globalHeaderNavigationConfig}
      theme="dark"
      userData={
        userState.isAuthenticated
          ? {
              ...userState.userData,
              // if 'accountsEmailVerification' flag is off, users email is treated as verified
              isEmailVerified:
                userState.userData.emailVerified || !accountsEmailVerification,
              isPremium: userState.userData.isSubscriber
            }
          : null
      }
    >
      <HeaderIcons />
    </GlobalHeader>
  );
};

export default Header;
