import tailwindConfig from '@outside-interactive/web-microfrontends.styles.tailwind-config';

export const getTailwindScreenSizes = () => {
  const tailwindScreenSizes: { [id: string]: string } =
    tailwindConfig('outside').theme.extend.screens;

  const removePx = (value: string) => parseInt(value.replace('px', ''), 10);

  return Object.entries(tailwindScreenSizes).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: removePx(value) }),
    {}
  );
};
