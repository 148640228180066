import { useAnalyticsContext } from 'providers/AnalyticsProvider';
import { getAbsoluteUrlPath } from 'helpers/utils/url/getAbsoluteUrlPath';
import Link from 'next/link';
import type { MouseEvent } from 'react';

import type { TrackingLinkProps } from './types';

const TrackingLink = (props: TrackingLinkProps) => {
  const { analytics, children, classes, isExternal, onClick, target, title } =
    props;
  const { elementClicked } = useAnalyticsContext();

  const onTrackingClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (analytics) {
      elementClicked({
        name: analytics.name,
        placement: analytics.placement,
        type: 'link',
        url: getAbsoluteUrlPath(String(href))
      });
    }
    onClick?.(event);
  };

  if (isExternal) {
    const { href } = props;
    return (
      <a
        aria-label={title}
        className={classes}
        href={href}
        onClick={onTrackingClick}
        target={target}
        title={title}
      >
        {children}
      </a>
    );
  }

  const { href } = props;

  return (
    <Link
      aria-label={title}
      className={classes}
      href={href}
      onClick={onTrackingClick}
      passHref
      prefetch={false}
      target={target}
      title={title}
    >
      {children}
    </Link>
  );
};

export default TrackingLink;
