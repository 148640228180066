import { QueryClient } from '@tanstack/react-query';

const options = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: Infinity
    }
  }
};

export const getQueryClient = () => new QueryClient(options);
